import {applyMiddleware, compose, createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';

import sagas from 'sagas';
import {combineResources} from 'utils/saga-resource';
import counter from './counter';
import user from './user';
import projects from './projects';
import project from './project';
import scan from './scan';
import executiveSummary from './executiveSummary';
import vuldbs from './vuldbs';
import vuldb from './vuldb';
import vulnerability from './vulnerability';
import scanVulnerability from './scanVulnerability';
import alert from './alert';
import notification from './notification';
import malwareIndex from './malwareIndex';
import malware from './malware';
import malwareDb from './malwareDb';
import search from './search';
import functions from './functions';
import softwares from './software';
import vulnerabilities from './vulnerabilities';
import componentDbs from './componentDbs';
import componentDb from './componentDb';
import hostDetails from 'store/hostDetails';
import hostMemorySnapshotDetails from 'store/hostMemorySnapshotDetails';
import hostsDashboard from 'store/hostsDashboard';
import containerResult from 'store/containerResult';
import firmwareResult from 'store/firmwareResult';

const composeEnhancer =
	(window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const combinedResources = combineResources({
	counter,
	user,
	projects,
	project,
	scan,
	executiveSummary,
	vuldbs,
	vuldb,
	vulnerability,
	scanVulnerability,
	alert,
	malwareIndex,
	malware,
	malwareDb,
	search,
	functions,
	softwares,
	vulnerabilities,
	componentDbs,
	componentDb,
	hostDetails,
	hostMemorySnapshotDetails,
	hostsDashboard,
	notification,
	containerResult,
	firmwareResult,
});

const sagaMiddleware = createSagaMiddleware();
const rootSaga = combinedResources.getSaga(sagas);
const reducers = combinedResources.combineReducers();

const store = createStore(
	reducers,
	composeEnhancer(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

export type AppState = ReturnType<typeof reducers>;

export default store;
