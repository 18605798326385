import {
	bindHover,
	bindPopover,
	usePopupState,
} from 'material-ui-popup-state/hooks';
import TranslateIcon from '@mui/icons-material/Translate';

import {List, ListItem, ListItemText} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Popover from 'material-ui-popup-state/HoverPopover';
import {supportedLanguages} from 'utils/i18n/config';
import {useTranslation} from 'react-i18next';

interface OwnProps {
	id: string;
}

const useStyles = makeStyles(() =>
	createStyles({
		link: {
			textDecoration: 'none !important',
			cursor: 'pointer',
			padding: '0 8px',
			display: 'flex',
			alignItems: 'center',
			minHeight: '40px',

			'&:hover': {
				background: 'rgba(255, 255, 255, .1)',
			},

			'& svg': {
				float: 'left', // remove inline-block padding bottom
			},
		},
		icon: {
			marginRight: '5px',
		},
		container: {
			display: 'flex',
			alignItems: 'center',
		},
	})
);

export const LanguageSwitcher: React.FC<OwnProps> = (props) => {
	const classes = useStyles();
	const popupState = usePopupState({
		variant: 'popover',
		popupId: props.id,
	});
	const {t, i18n} = useTranslation();

	return (
		<div>
			<ListItem
				{...bindHover(popupState)}
				color="inherit"
				className={classes.link}
			>
				<span className={classes.container}>
					<span className={classes.icon}>
						<TranslateIcon />
					</span>
					{t('languageChooser')}
				</span>
			</ListItem>
			{supportedLanguages && (
				<Popover
					{...bindPopover(popupState)}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					disableRestoreFocus
				>
					<List>
						{supportedLanguages.map((item) => (
							<ListItemLink
								key={item.code}
								style={{cursor: 'pointer'}}
								onClick={async (): Promise<any> =>
									await i18n.changeLanguage(item.code)
								}
							>
								<ListItemText primary={item.name} />
							</ListItemLink>
						))}
					</List>
				</Popover>
			)}
		</div>
	);
};

const ListItemLink = (props): JSX.Element => {
	return <ListItem button {...props} />;
};
