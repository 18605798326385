import {makeResource} from 'utils/saga-resource';
import {put} from 'redux-saga/effects';
import {callApi} from 'utils/network';

interface ExecutiveSummary {
	assetsScanned: number;
	totalVulnerability: number;
	criticalVulnerability: number;
	highVulnerability: number;
	mediumVulnerability: number;
	criticalHighAssetRatio: number;
	top5FrequentComponents: string[];
	licenseTypes: number;
	commercialLicense: number;
	nonCommercialLicense: number;
	vulnerabilityIncreaseRatio: number;
	criticalVulnerabilityIncreaseRatio: number;
	createdAt: Date;
}

export interface ExecutiveSummaryEffects {
	loadResource: () => any;
	load: () => any;
}

const executiveSummary = makeResource<
	ExecutiveSummary,
	any,
	ExecutiveSummaryEffects
>({
	name: 'executiveSummary',
	state: {
		assetsScanned: 0,
		totalVulnerability: 0,
		criticalVulnerability: 0,
		highVulnerability: 0,
		mediumVulnerability: 0,
		criticalHighAssetRatio: 0,
		top5FrequentComponents: [],
		licenseTypes: 0,
		commercialLicense: 0,
		nonCommercialLicense: 0,
		vulnerabilityIncreaseRatio: 0,
		criticalVulnerabilityIncreaseRatio: 0,
		createdAt: new Date(),
	},
	effects: {
		*loadResource(): any {
			const data = (yield callApi('/api/v1/executive_summary')).data;
			yield put(executiveSummary.actions.set(data));
		},
		*load(): any {
			yield put(executiveSummary.actions.startLoading());
			yield executiveSummary.effects.loadResource();
			yield put(executiveSummary.actions.endLoading());
		},
	},
});

export default executiveSummary;
